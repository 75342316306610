/* jshint ignore:start */
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages

        /**
         * Add a .scrolled class to the body as soon as <body> is scrolled at least 180px.
         * This is needed to control the main menu behavior after scrolling.
         */

        var scroll = $(window).scrollTop();

        if (scroll > 180 && !$("body").hasClass("scrolled")) {
          $("body").addClass("scrolled");
        } else if (scroll <= 180) {
          $("body").removeClass("scrolled menuOpened");
          $("#openExtendedMenu").removeClass("is-active");
        }
        $(window).on("scroll gesturechange", function () {
          var scroll = $(window).scrollTop();
          if (scroll > 180 && !$("body").hasClass("scrolled")) {
            $("body").addClass("scrolled");
          } else if (scroll <= 180) {
            $("body").removeClass("scrolled menuOpened");
            $("#openExtendedMenu").removeClass("is-active");
          }
        });

        /**
         * Open and close the extended/mobile menu.
         */
        $("#openExtendedMenu").on("click", function () {
          $("body").toggleClass("menuOpened");
          $(this).toggleClass("is-active");
        });

        /**
         * Animated scroll to anchor links when clicked (only if they exists).
         */
        $(document).on("click", 'a[href^="#"]', function (event) {
          event.preventDefault();
          if ($($.attr(this, "href")).length > 0) {
            $("html, body").animate(
              {
                scrollTop: $($.attr(this, "href")).offset().top - 160, //offsets for fixed header
              },
              500
            );
          }
        });

        /**
         * Open and close filters drowpdown menu.
         */
        $(".postFilters").on("click", function () {
          var clickedEl = this.classList[0];
          $(".postFilters").each( function () {
              if ( $(this).hasClass(clickedEl) === true ) {
                $(this).toggleClass("filtersOpen");
                var ddList = "." + $(this).data("dropdown");
                $(this).find(ddList).slideToggle(200);
              } else {
                if ( $(this).hasClass("filtersOpen") === true) {
                  var ddList = "." + $(this).data("dropdown");
                  $(this).find(ddList).slideToggle(200);
                };
                $(this).removeClass("filtersOpen");
              };
          });
        });

        // populate hidden ac/gf link tags field with any preset custom link tags
        var linkTagsEL = $("form input.gform_hidden[value='(not set)']");

        if (linkTagsEL.length > 0) {
          var splitURL = window.location.href.split("?");
          var linkTags = "(not set)";

          for (var val in splitURL) {
            if (splitURL[val].includes("#") === true) {
              var splitTags = splitURL[val].split("#");
              linkTags = "";
              for (var count in splitTags) {
                if (count === "0") continue;
                linkTags = linkTags + splitTags[count].toString() + " ";
              }
              break;
            }
          }

          linkTagsEL.val(linkTags.trim());
        }

        var el = $('._form_27 input[name="field[46]"]')[0];
        if (el) {
          el.value = window.location.href;
        }

        $(".hubspot_form_shortcode .btn-show-form").on("click", function (event) {
          event.preventDefault();
          event.currentTarget.classList.toggle("hidden");
          $(".hubspot_form_shortcode .hs_form_expander").toggleClass("hs_hidden");
        });

        $(".copyToClipboard").on("click", function (event) {
          event.preventDefault();

          var $clickVal = $(event.currentTarget)
            .parent(".socialSharingLinks")
            .data("url");

          var $temp = $("input");
          $("body").append($temp);
          $temp.val($clickVal).select();
          document.execCommand("copy");
          $temp.remove();

          $(".copySuccess").on("animationend", function (event) {
            var $el = $(event.currentTarget);
            $el.removeClass("copySuccess-visible");
            $el.addClass("copySuccess-hidden");
          });

          var $cs = $(".copySuccess");
          $cs.removeClass("copySuccess-hidden");
          $cs.addClass("copySuccess-visible");
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
